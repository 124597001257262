import React from 'react';
import _ from 'lodash';

import {safePrefix} from '../utils';
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default class CtaButtons extends React.Component {
    render() {
        return (
            <p className="block-buttons">
              {_.map(_.get(this.props, 'actions'), (action, action_idx) => (
              <AnchorLink key={action_idx} to={safePrefix(_.get(action, 'url'))} className="button secondary">{_.get(action, 'label')}</AnchorLink>
              ))}
            </p>
        );
    }
}
