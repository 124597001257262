import React from 'react';
import _ from 'lodash';

import {htmlToReact, markdownify} from '../utils';

export default class SectionContact extends React.Component {
    render() {
        let section = _.get(this.props, 'section');
        return (
            <section id={_.get(section, 'section_id')} className={'block contact-block bg-' + _.get(section, 'bg') + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title') && 
                <h2 className="block-title">{_.get(section, 'title')}</h2>
                }
                {_.get(section, 'subtitle') && 
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle'))}
                </p>
                }
              </div>
              <div className="block-content inner-medium">
                {markdownify(_.get(section, 'content'))}
                <form action="/success" name="contactForm" method="POST" netlifyHoneypot="bot-field" data-netlify="true" id="contact-form"
                  className="contact-form">
                  <p className="screen-reader-text">
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                  </p>
                  <div className="form-row">
                    <label htmlFor="name" className="form-label hidden">Name</label>
                    <input type="text" name="name" className="form-input" placeholder="Name" required/>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="email" className="form-label hidden">Email address</label>
                    <input type="email" name="email" className="form-input" placeholder="Email Address" required/>
                  </div>
                  <div className="form-row checkbox-wrapper">
                    <p>Services required</p>
                    <div className="select-wrapper">
                    <input type="checkbox" id="group-walks" name="walks"/>
                    <label htmlFor="walks">Group walks</label>
                    </div>
                    <div className="select-wrapper">
                    <input type="checkbox" id="day-care" name="day-care"/>
                    <label htmlFor="day-care">Day care</label>
                    </div>
                    <div className="select-wrapper">
                    <input type="checkbox" id="home-boarding" name="home-boarding"/>
                    <label htmlFor="home-boarding">Home boarding</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="telephone" className="form-label hidden">Telephone number</label>
                    <input type="tel" name="telephone" className="form-input" placeholder="Telephone number" required/>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="postcode" className="form-label hidden">Postcode</label>
                    <input type="text" name="postcode" className="form-input" placeholder="Postcode" required/>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="dogs-name" className="form-label hidden">Dog's name</label>
                    <input type="text" name="dogs-name" className="form-input" placeholder="Dog's name" required/>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="breed" className="form-label hidden">Breed</label>
                    <input type="text" name="breed" className="form-input" placeholder="Breed" required/>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="age" className="form-label hidden">Age</label>
                    <input type="text" name="age" className="form-input" placeholder="Age" required/>
                  </div>
                  <div className="form-row radio-wrapper">
                    <p>Sex</p>

                    <label>
                      <input type="radio" name="sex" id="male" value="male" required /> Male
                    </label>

                    <label>
                      <input type="radio" name="sex" id="female" value="female" required /> Female
                    </label>
                    
                  </div>
                  <div className="form-row radio-wrapper">
                    <p>Is your dog neutered?</p>
                    
                    <label>
                      <input type="radio" name="neutered" id="neutered-yes" value="neutered-yes" required /> Yes
                    </label>

                    <label>
                      <input type="radio" name="neutered" id="neutered-no" value="neutered-no" required /> No
                    </label>
                  </div>
                  <div className="form-row">
                    <label  htmlFor="information" className="form-label hidden">Additional information</label>
                    <textarea name="information" className="form-textarea" rows="7" placeholder="Additional information" />
                  </div>
                  <input type="hidden" name="form-name" value="contactForm" />
                  <div className="form-row form-submit">
                    <button type="submit" className="button primary large">Send</button>
                  </div>
                </form>
              </div>
            </section>
        );
    }
}
